import { UsersService } from '@/api/api.service'
import {
  USERS_BLOCK_USER, USERS_CREATE_USER, USERS_GET_USERS, USERS_REMOVE_SESSION, USERS_REMOVE_USER, USERS_RESET_EMAIL, USERS_SET_PASSWORD, USERS_UNBLOCK_USER, USERS_UPDATE_USER
} from '@/store/actions.type'
import { USERS_SELECT_USER, USERS_SET_USERS, USERS_UPDATE_SESSION } from '@/store/mutations.type'

const initialState = {
  users: [],
  selectedUser: {}
}

export const state = Object.assign({}, initialState)

export const actions = {
  async [USERS_CREATE_USER] (context, user) {
    const response = await UsersService.createUser(user)
    if (response.status === 201) {
      context.commit(USERS_CREATE_USER, response.data)
    }
  },
  async [USERS_REMOVE_USER] (context, user) {
    const response = await UsersService.removeUser(user)
    if (response.status === 200) {
      context.commit(USERS_REMOVE_USER, user)
    }
  },
  async [USERS_UPDATE_USER] (context, user) {
    const response = await UsersService.updateUser(user)
    if (response.status === 200) {
      context.commit(USERS_UPDATE_USER, response.data)
    }
  },
  async [USERS_GET_USERS] (context) {
    const { data } = await UsersService.getUsers()
    context.commit(USERS_SET_USERS, data)
  },
  async [USERS_BLOCK_USER] (context, user) {
    const response = await UsersService.blockUser(user)
    if (response.status === 200) {
      context.commit(USERS_BLOCK_USER, response.data)
    }
  },
  async [USERS_UNBLOCK_USER] (context, user) {
    const response = await UsersService.unblockUser(user)
    if (response.status === 200) {
      context.commit(USERS_UNBLOCK_USER, response.data)
    }
  },
  async [USERS_SET_PASSWORD] (context, params) {
    await UsersService.setPassword(params.resetPasswordToken, params.newPassword)
  },
  async [USERS_RESET_EMAIL] (context, email) {
    await UsersService.sendPasswordReset(email)
  },
  async [USERS_REMOVE_SESSION] (context, session) {
    const response = await UsersService.removeSession(session.id)
    if (response.status === 200) {
      context.commit(USERS_UPDATE_SESSION, session)
    }
  }
}

export const mutations = {
  [USERS_UPDATE_SESSION] (state, data) {
    state.users = state.users.map(u => {
      if (u.id === data.userId) {
        u.session = null
      }
      return u
    })
  },
  [USERS_CREATE_USER] (state, data) {
    state.users.push(data)
  },
  [USERS_REMOVE_USER] (state, user) {
    var index = state.users.findIndex(u => u.id === user.id)
    state.users.splice(index, 1)
  },
  [USERS_UPDATE_USER] (state, user) {
    var index = state.users.findIndex(u => u.id === user.id)
    state.users[index] = user
  },
  [USERS_SET_USERS] (state, users) {
    state.users = users
  },
  [USERS_SELECT_USER] (state, id) {
    var index = state.users.findIndex(u => u.id === id)
    state.selectedUser = state.users[index]
  },
  [USERS_BLOCK_USER] (state, user) {
    var index = state.users.findIndex(u => u.id === user.id)
    state.users[index].isActive = false
  },
  [USERS_UNBLOCK_USER] (state, user) {
    var index = state.users.findIndex(u => u.id === user.id)
    state.users[index].isActive = true
  }
}

export const getters = {
  getUsers: state => {
    return state.users
  },
  getSelectedUser: state => {
    return state.selectedUser
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
