<template>
  <v-navigation-drawer
    :mini-variant.sync="getSettingsCollapseSidebar"
    :value="drawer"
    app
    clipped
    mini-variant-width="70"
    :width="250"
  >
    <v-list dense>
      <v-list-item link :to="'/issues'">
        <v-list-item-icon>
          <v-icon>mdi-clipboard-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Guardias activos</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/tracking'">
        <v-list-item-icon>
          <v-icon>mdi-map-marker-radius</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Usuarios</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/places'">
        <v-list-item-icon>
          <v-icon>mdi-map-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Zonas</v-list-item-title>
      </v-list-item>

      <!--
      <v-list-item link :to="'/reports'">
        <v-list-item-icon>
          <v-icon>mdi-virus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Reportes</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/admins'">
        <v-list-item-icon>
          <v-icon>mdi-account-tie</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Administradores</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/government-personnel'">
        <v-list-item-icon>
          <v-icon>mdi-account-tie-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Personal de gobierno</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/covid-19-personnel'">
        <v-list-item-icon>
          <v-icon>mdi-badge-account-horizontal</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Personal COVID-19</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/routes'">
        <v-list-item-icon>
          <v-icon>mdi-map-marker-path</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Rutas</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/questions'">
        <v-list-item-icon>
          <v-icon>mdi-comment-question</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Preguntas</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/categories'">
        <v-list-item-icon>
          <v-icon>mdi-view-list</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Categorías</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="'/information'">
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Acerca de</v-list-item-title>
      </v-list-item>
      -->
      <template v-if="getSettingsCollapseSidebar">
      <v-menu open-on-hover top offset-x link>
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-settings</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Configuración</v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item v-for="item in settings.items" :key="item.title" :to="item.to">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      </template>
      <template v-if="!getSettingsCollapseSidebar">
        <v-list-group v-model="settings.active" :prepend-icon="settings.icon" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="settings.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="item in settings.items" :key="item.title" :to="item.to" dense>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TheSidebar',
  props: {},
  data() {
    return {
      drawer: true,
      settings: {
        icon: 'mdi-settings',
        title: 'Configuración',
        items: [
          { title: 'Organización', to: '/settings/organization' },
          { title: 'Aplicación', to: '/settings/application' },
          { title: 'Usuarios', to: '/settings/users' },
          { title: 'Roles', to: '/settings/rols' },
          { title: 'Grupos', to: '/settings/groups' },
          { title: 'Tipo Incidencias', to: '/settings/issue-types' },
          { title: 'Estatus Incidencias', to: '/settings/issue-status' },
          { title: 'Campos', to: '/settings/fields' },
          { title: 'Formularios', to: '/settings/forms' },
          { title: 'Flujos', to: '/settings/workflows' }
        ]
      }
    }
  },
  computed: { ...mapGetters(['getSettingsCollapseSidebar']) },
  methods: {},
  components: {}
}
</script>
