export default {
  primary: '#891439',
  secondary: '#60162D',
  titleHeader: '#5F5F64',
  accent: '#82B1FF',
  error: '#FF3739',
  info: '#fff',
  success: '#00D014',
  warning: '#FFB70F',
  background: '#313133'
}
