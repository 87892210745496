/* eslint-disable no-console */
import JwtService from '@/api/jwt.service'
import AppConfig from '@/constants/app-config'
import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    console.log('Base URL is:', AppConfig.apiUrl)
    Vue.axios.defaults.baseURL = AppConfig.apiUrl

    axios.interceptors.request.use(
      function(config) {
        return config
      },
      function(error) {
        window.scrollTo(0, 0)
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      function(response) {
        return response
      },
      function(err) {
        const response = err.response.data
        return Promise.reject(response)
        // window.scrollTo(0, 0)
      }
    )
  },

  setHeaders() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`
  },

  query(resource, params) {
    this.setHeaders()
    return Vue.axios.get(resource, params)
  },

  get(resource, slug = '') {
    this.setHeaders()
    return Vue.axios.get(`${resource}/${slug}`)
  },

  post(resource, payload, params) {
    this.setHeaders()
    return Vue.axios.post(`${resource}`, payload, params)
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  delete(resource, params) {
    return Vue.axios.delete(resource, { data: params })
  }
}

export default ApiService

/* ----------------------------------------------------------------------
>>> Application Service
---------------------------------------------------------------------- */

export const ApplicationService = {
  getSettings(params) {
    return ApiService.query('application/settings', {
      params
    })
  },
  updateSettings(settings) {
    return ApiService.put('application/settings', settings)
  }
}

/* ----------------------------------------------------------------------
>>> Fields Service
---------------------------------------------------------------------- */

export const Fields2Service = {
  getFields(params) {
    return ApiService.query('fields', {
      params
    })
  }
}

/* ----------------------------------------------------------------------
>>> Files Service
---------------------------------------------------------------------- */

export const FilesService = {
  createFile(file) {
    return ApiService.post('files', file)
  },
  uploadFile({ file, uploadUrl }) {
    delete axios.defaults.headers.common.Authorization
    return Vue.axios.put(uploadUrl, file, {
      headers: { 'Content-Type': file.type }
    })
  }
}

/* ----------------------------------------------------------------------
>>> Forms Service
---------------------------------------------------------------------- */

export const FormsService = {
  getForms(params) {
    return ApiService.query('forms', {
      params
    })
  },
  createForm(form) {
    return ApiService.post('forms', form)
  },
  getForm(formId) {
    return ApiService.query(`forms/${formId}`)
  },
  updateForm(form) {
    return ApiService.put(`forms/${form.id}`, form)
  }
}

/* ----------------------------------------------------------------------
>>> Issues Service
---------------------------------------------------------------------- */

export const IssuesService = {
  getIssues(params) {
    return ApiService.query('issues', {
      params
    })
  },
  getIssue(issueId) {
    return ApiService.query(`issues/${issueId}`)
  },
  getChangelog(issueId) {
    return ApiService.query(`issues/${issueId}/changelog`)
  }
}

/* ----------------------------------------------------------------------
>>> Issues Types Service
---------------------------------------------------------------------- */

export const IssueTypesService = {
  getIssueTypes(params) {
    return ApiService.query('issue-types', {
      params
    })
  },
  getIssueType(issueTypeId) {
    return ApiService.query(`issue-types/${issueTypeId}`)
  },
  createIssueType(issueType) {
    return ApiService.post('issue-types', issueType)
  },
  updateIssueType(issueType) {
    return ApiService.put(`issue-types/${issueType.id}`, issueType)
  }
}

/* ----------------------------------------------------------------------
>>> Issues Status Service
---------------------------------------------------------------------- */

export const IssueStatusService = {
  getIssueStatuses(params) {
    return ApiService.query('issue-status', {
      params
    })
  },
  getIssueStatus(issueStatusId) {
    return ApiService.query(`issue-status/${issueStatusId}`)
  },
  createIssueStatus(issueStatus) {
    return ApiService.post('issue-status', issueStatus)
  },
  updateIssueStatus(issueStatus) {
    return ApiService.put(`issue-status/${issueStatus.id}`, issueStatus)
  },
  getIssueStatusTypes(params) {
    return ApiService.query('issue-status-types', {
      params
    })
  }
}

/* ----------------------------------------------------------------------
>>> Places Service
---------------------------------------------------------------------- */

export const PlacesService = {
  getPlaces(params) {
    return ApiService.query('places', {
      params
    })
  },
  getPlace(placeId) {
    return ApiService.query(`places/${placeId}`)
  },
  updatePlace(placeId, place) {
    return ApiService.put(`places/${placeId}`, place)
  },
  deletePlace(placeId) {
    return ApiService.delete(`places/${placeId}`)
  },
  deletePlaceUser(placeId, userId) {
    return ApiService.delete(`places/${placeId}/users/${userId}`)
  },
  addPlaceUser(placeId, userId) {
    return ApiService.post(`places/${placeId}/users/${userId}`)
  },
  getPlaceTypes() {
    return ApiService.query('place-types')
  },
  createPlace(place) {
    return ApiService.post('places', place)
  }
}

/* ----------------------------------------------------------------------
>>> Users Service
---------------------------------------------------------------------- */

export const UsersService = {
  query: params => {
    return ApiService.query('users', {
      params
    })
  },
  createUser: user => {
    return ApiService.post('users/', user)
  },
  removeUser: user => {
    return ApiService.delete('users/' + user.id)
  },
  updateUser: user => {
    return ApiService.put('users/' + user.id, user)
  },
  getUsers: () => {
    return ApiService.get('users')
  },
  blockUser: user => {
    return ApiService.post('users/' + user.id + '/block')
  },
  unblockUser: user => {
    return ApiService.delete('users/' + user.id + '/block')
  },
  setPassword: (resetPasswordToken, newPassword) => {
    return ApiService.post('users/password/', {
      resetPasswordToken,
      newPassword
    })
  },
  sendPasswordReset: email => {
    return ApiService.delete('users/password/', { email })
  },
  hasIssues: user => {
    return ApiService.get(`users/${user.id}/has/issues`)
  },
  removeSession: sessionId => {
    return ApiService.delete(`/sessions/${sessionId}`)
  }
}

/* ----------------------------------------------------------------------
>>> Groups Service
---------------------------------------------------------------------- */

export const RolesService = {
  query: params => {
    return ApiService.query('rols', {
      params
    })
  },
  getRoles: () => {
    return ApiService.get('rols')
  },
}

/* ----------------------------------------------------------------------
>>> Groups Service
---------------------------------------------------------------------- */

export const GroupsService = {
  query: params => {
    return ApiService.query('users', {
      params
    })
  },
  createGroup: group => {
    return ApiService.post('groups', group)
  },
  removeGroup: group => {
    return ApiService.delete('groups/' + group.id)
  },
  updateGroup: group => {
    return ApiService.put('groups/' + group.id)
  },
  getGroups: () => {
    return ApiService.get('groups')
  },
  getGroup: groupId => {
    return ApiService.get('groups/' + groupId)
  },
  getGroupMembers: group => {
    return ApiService.get('groups/' + group.id + '/members')
  },
  addMember: (group, user) => {
    return ApiService.post('groups/' + group.id + '/members/' + user.id)
  },
  removeMember: (group, user) => {
    return ApiService.delete('groups/' + group.id + '/members/' + user.id)
  }
}

/* ----------------------------------------------------------------------
>>> Fields Service
---------------------------------------------------------------------- */
export const FieldsService = {
  createField: field => {
    return ApiService.post('fields/', field)
  },
  removeField: field => {
    return ApiService.delete('fields/' + field.id)
  },
  updateField: field => {
    return ApiService.put('fields/' + field.id, field)
  },
  getForms: () => {
    return ApiService.get('forms')
  },
  getFields: () => {
    return ApiService.get('fields')
  },
  getField: fieldId => {
    return ApiService.get('fields/' + fieldId)
  },
  getFieldTypes: () => {
    return ApiService.get('field-types')
  },
  getFieldOptions: fieldId => {
    return ApiService.get('fields/' + fieldId + '/options')
  },
  addOption: (fieldId, value) => {
    return ApiService.post('fields/' + fieldId + '/options', { value })
  },
  removeOption: (fieldId, option) => {
    return ApiService.delete('fields/' + fieldId + '/options/' + option.id)
  }
}

/* ----------------------------------------------------------------------
>>> Sessions Service
---------------------------------------------------------------------- */

export const SessionsService = {
  logout: () => {
    return ApiService.post('users/logout')
  },
  login: credentials => {
    return ApiService.post('users/login', credentials)
  },
  me: () => {
    return ApiService.get('me')
  },
  verifyToken: (token) => {
    return ApiService.post('sessions/verify-token/' + token)
  }
}

/* ----------------------------------------------------------------------
>>> Timelines Service
---------------------------------------------------------------------- */

export const TimelinesService = {
  getTimelines(params) {
    return ApiService.query('timelines', {
      params
    })
  },
  getTimeline(userId, params) {
    return ApiService.query(`timelines/users/${userId}`, {
      params
    })
  }
}

/* ----------------------------------------------------------------------
>>> Tracking Service
---------------------------------------------------------------------- */

export const TrackingService = {
  getTracking(params) {
    return ApiService.query('tracking', {
      params
    })
  },
  getTrack(userId, params) {
    return ApiService.query(`tracking/users/${userId}`, {
      params
    })
  }
}

/* ----------------------------------------------------------------------
>>> Workflows Service
---------------------------------------------------------------------- */

export const WorkflowsService = {
  getWorkflows(params) {
    return ApiService.query('workflows', {
      params
    })
  },
  getWorkflow(workflowId) {
    return ApiService.query(`workflows/${workflowId}`)
  },
  createWorkflow(workflow) {
    return ApiService.post('workflows', workflow)
  },
  updateWorkflow(workflow) {
    return ApiService.put(`workflows/${workflow.id}`, workflow)
  },
  getWorkflowTransitions(workflowId) {
    return ApiService.query(`workflows/${workflowId}/transitions`)
  },
  getWorkflowTransition(workflowId, transitionId) {
    return ApiService.query(
      `workflows/${workflowId}/transitions/${transitionId}`
    )
  },
  createTransition(workflowId, transition) {
    return ApiService.post(`workflows/${workflowId}/transitions`, transition)
  },
  updateTransition(workflowId, transitionId, transition) {
    return ApiService.put(
      `workflows/${workflowId}/transitions/${transitionId}`,
      transition
    )
  },
  deleteTransition(workflowId, transitionId) {
    return ApiService.delete(
      `workflows/${workflowId}/transitions/${transitionId}`
    )
  }
}
