const initialState = {
  roles: {}
}

export const state = Object.assign({}, initialState)

const actions = {

}

const mutations = {

}

const getters = {

}

export default {
  state,
  actions,
  mutations,
  getters
}
